<template>
  <DataTable
    :value="tableData"
    filterDisplay="menu"
    showGridlines
    dataKey="id"
    v-model:filters="filters"
    @sort="sort"
    removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>
    <Column field="id" header="No." headerStyle="width: 55px"/>
    <Column field="nombreCliente" header="Cliente" />
    <Column header="Rango">
      <template #body="{data}">
        <span>{{data.mesInicial }} - {{ data.mesFinal}}</span>
      </template>
    </Column>
    <Column field="anio" header="Año" />
    <Column field="tipo" header="Tipo" filterField="tipo">
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="tipos" optionLabel="name" optionValue="code" placeholder="Seleccione una opción" :filter="true" />
      </template>
    </Column>
    <Column field="createdAt" header="Fecha de creación" filterField="createdAt" sortField="createdAt" :sortable="true" dataType="date">
      <template #filter="{filterModel}">
        <input v-model="filterModel.value" placeholder="YYYY-MM-DD" type="date" class="p-inputtext">
      </template>
    </Column>
    <Column header="Opciones" >
      <template #body="{data}">
        <div class="flex justify-around lg:mx-8">
          <button class="bg-primary-1 text-white px-3 py-2 rounded-md" v-tooltip.top="'Ver informe'" @click="verPdf(data.ruta)">
            <i class="pi pi-eye"></i>
          </button>
          <button class="bg-green-600 text-white px-3 py-2 ml-3 rounded-md"  v-tooltip.top="'Actualizar información'" @click="updateDataInforme(data.id)" >
            <i class="pi pi-refresh"></i>
          </button>
          <button class="bg-red-600 text-white px-3 py-2 ml-3 rounded-md"  v-tooltip.top="'Eliminar informe'" @click="deleteInforme(data.id)" >
            <i class="pi pi-times"></i>
          </button>
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginate.first" :rows="paginate.rows" :totalRecords="total" :rowsPerPageOptions="[10,20,30]" @page="onPage($event)" ></Paginator>
</template>

<script>
import storeInformesEjecucion from '../store/informeEjecucion.store'
import { removeInforme, update } from '../services/informeEjecutivo.service'
import { computed, onMounted, ref, watch } from 'vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import PdfService from '../services/verPdf.service'
import Swal from 'sweetalert2'
export default {
  name: 'tableList',
  setup () {
    /* instancias */
    const tipos = ref(
      [
        { code: 'Evento', name: 'EVENTO' },
        { code: 'Capita', name: 'CAPITA' }
      ]
    )
    const order = ref(null)
    // const _removeInforme = ref(new removeInforme())
    const _PdfService = new PdfService()
    const filters = ref({
      // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      tipo: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.EQUALS
          }
        ]
      },
      createdAt: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.DATE_IS
          }
        ]
      }
    })
    /* computed */
    const tableData = computed(() => storeInformesEjecucion.getters.getListado)
    const paginate = computed(() => storeInformesEjecucion.getters.getPaginate)
    const total = computed(() => storeInformesEjecucion.getters.getTotal)
    /* metodos */
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const getListado = () => {
      const params = {
        filters: filters.value,
        order: order.value
      }
      storeInformesEjecucion.dispatch('getListadoInforme', params)
    }
    const openInforme = (id) => {
      console.log(id, 'abrir informe')
    }
    const updateDataInforme = (id) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: `<strong style="line-height: initial">Esta seguro de actualizar el informe</strong> ${id}?`,
        heightAuto: false,
        text: 'Despues de aceptar no puede deshacer esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<div>Si, actualizar!</div>',
        cancelButtonText: 'No, cancelar!',
        buttonsStyling: 'justify-content: space-around',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          update(id).then(() => {
            swalWithBootstrapButtons.fire(
              'Actualizado!',
              `Actualizaste el informe ${id}`,
              'success'
            ).catch((e) => {
              console.log(e)
            })
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelado',
            `No se actualizo el informe ${id}`,
            'error'
          )
        }
      })
    }
    const deleteInforme = (id) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: `<strong style="line-height: initial">Esta seguro de eliminar el informe</strong> ${id}?`,
        heightAuto: false,
        text: 'Despues de aceptar no puede deshacer esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<div>Si, eliminar!</div>',
        cancelButtonText: 'No, cancelar!',
        buttonsStyling: 'justify-content: space-around',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          removeInforme(id).then(({ data }) => {
            swalWithBootstrapButtons.fire(
              'Eliminado!',
              `Eliminaste el informe ${id}`,
              'success'
            ).then(() => {
              getListado()
            })
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelado',
            `El informe ${id} se encuentra a salvo`,
            'error'
          )
        }
      })
    }
    const sort = (evt) => {
      const { sortField, sortOrder } = evt
      if (sortField && sortOrder) {
        order.value = { column: sortField, order: sortOrder === 1 ? 'ASC' : 'DESC' }
      } else {
        order.value = null
      }
      getListado()
    }
    const onPage = (evt) => {
      const { rows, first, page } = evt
      const paginateP = {
        rows,
        first,
        offset: page
      }
      storeInformesEjecucion.commit('setPaginate', paginateP)
      getListado()
    }
    /* hooks */
    onMounted(() => {
      getListado()
    })

    watch(filters, () => {
      const paginateP = {
        rows: paginate.value.rows, // limit
        first: 1,
        offset: 0
      }
      storeInformesEjecucion.commit('setPaginate', paginateP)
      getListado()
    })

    return {
      tableData,
      paginate,
      total,
      tipos,
      filters,
      openInforme,
      sort,
      onPage,
      updateDataInforme,
      verPdf,
      deleteInforme
    }
  }
}
</script>

<style scoped>
  .swal2-actions {
    justify-content: space-around !important;
  }

</style>
